import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from 'react-redux';
import store from "./ReduxStore/store"
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://090c7e0df5165fb035e2234106c1c658@o4508572512485376.ingest.us.sentry.io/4508595673956352",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],

//   tracesSampleRate: 1.0, 
//   tracePropagationTargets: ["localhost", /^https:\/\/www.theinteria.com/],

//   replaysSessionSampleRate: 0.1, 
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


