import React, { createContext, useContext, useEffect, useState } from 'react';

const SourceUrlContext = createContext();

export const SourceUrlProvider = ({ children }) => {
  const storedSourceUrl = sessionStorage.getItem("sourceUrlSession");
  const [sourceUrl, setSourceUrl] = useState(storedSourceUrl);

  useEffect(() => {
    if (!storedSourceUrl) {
      const referrer = document.referrer || window.location.href;
      sessionStorage.setItem("sourceUrlSession", referrer);
      setSourceUrl(referrer);
    }
  }, [storedSourceUrl]);

  return (
    <SourceUrlContext.Provider value={{ sourceUrl }}>
      {children}
    </SourceUrlContext.Provider>
  );
};

export const useSourceUrl = () => useContext(SourceUrlContext);