import { lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Faq = lazy(() => import("./Pages/Faq/Faq"));
const Project = lazy(() => import("./Pages/Project/Project"));
const ProjectDetail = lazy(() => import("./Pages/Project/ProjectDetail"));
const Media = lazy(() => import("./Pages/Media/Media"));
const Services = lazy(() => import("./Pages/Services/Services"));
const ServicesListing = lazy(() => import("./Pages/Services/ServiceListing"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Career = lazy(() => import("./Pages/Career/Career"));
const CareerDetail = lazy(() => import("./Pages/Career/CareerDetail"));
const BlogListing = lazy(() => import("./Pages/blogListing/BlogListing"));
const BlogDetail = lazy(() => import("./Pages/blogListing/BlogDetail"));
const Thankyou = lazy(() => import("./Pages/Thankyou/Thankyou"));
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const ServiceLanding = lazy(() => import("./Pages/ServiceLanding/ServiceLanding"));
const MainLanding = lazy(() => import("./Pages/MainLanding/MainLanding"));
const CategoryLanding = lazy(() => import("./Pages/CategoryLanding/CategoryLanding"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./Pages/TermsConditions/TermsConditions"));
export default function RouterFie() {
  
  const RedirectToInnovations = () => {
    const location = useLocation();
    const newPath = location.pathname.replace("/blogs/innovation/", "/blogs/innovations/");
    return <Navigate to={newPath} replace />;
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/project" element={<Project />} />
        <Route path="/project/:slug" element={<ProjectDetail />} />
        <Route path="/media" element={<Media />} />
        <Route path="/:slug" element={<Services />} />
        <Route path="/service-listing" element={<ServicesListing />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/blogs" element={<BlogListing />} />
        <Route path="/blogs/:category/:slug" element={<BlogDetail />} />
        <Route path="/career/:slug" element={<CareerDetail />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/something-went-wrong" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/luxury-interior-design-company" element={<ServiceLanding />} />
        <Route path="/interior-designer-in-gurgaon" element={<MainLanding />} />
        <Route path="/residential-interior-design" element={<CategoryLanding />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/blogs/innovation/*" element={<RedirectToInnovations />} />

        {/* //redirects */}
        <Route path="/index.html" element={<Navigate to="/" replace />} />

        <Route path="/interiors" element={<Navigate to="/luxury-interior-design-company" replace />} />

        <Route path="/residential-interior-designers-in-south-delhi" element={<Navigate to="/interior-designers-in-south-delhi" replace />} />

        <Route path="/interior-design-studio-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/wardrobe-designs-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/office-interior-designers-vasant-kunj" element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />} />

        <Route path="/pioneer-araya-luxury-interiors-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/ireo-skyon-interior-designs-in-gurgaon" element={<Navigate to="/project/ireo-skyon-sector-60-gurugram-interior-designer" replace />} />

        <Route path="/best-interior-designers-in-vasant-kunj" element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />} />

        <Route path="/pioneer-araya-interior-designs-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/bestech-park-view-spa-luxury-interiors-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/dlf-the-magnolias-luxury-interiors-in-gurgaon" element={<Navigate to="/project/dlf-the-magnolias-gurgaon-luxury-interior-designers" replace />} />

        <Route path="/vipul-tatvam-villas-luxury-interiors-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/m3m-golf-estate-interiors-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/residential-interior-designers-vasant-kunj" element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />} />

        <Route path="/office-interior-designers-in-greater-kailash" element={<Navigate to="/interior-designers-in-greater-kailash" replace />} />

        <Route path="/m3m-golf-estate-luxury-interiors-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/residential-interior-designers-in-greater-kailash" element={<Navigate to="/interior-designers-in-greater-kailash" replace />} />

        <Route path="/dlf-the-crest-interior-design-ideas-in-gurgaon" element={<Navigate to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers" replace />} />

        <Route path="/interior-designers-vasant-kunj" element={<Navigate to="/luxury-interior-designers-in-vasant-kunj" replace />} />

        <Route path="/vipul-tatvam-villas-interior-designs-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/central-park-2-resorts-interior-designs-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/luxury-interior-designers-in-hauz-khas" element={<Navigate to="/residential-interior-designers-hauz-khas-delhi" replace />} />

        <Route path="/ireo-skyon-luxury-interiors-in-gurgaon" element={<Navigate to="/project/ireo-skyon-sector-60-gurugram-interior-designer" replace />} />

        <Route path="/bestech-park-view-spa-interior-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/dlf-the-crest-luxury-interiors-in-gurgaon" element={<Navigate to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers" replace />} />

        <Route path="/dlf-the-crest-interior-designs-in-gurgaon" element={<Navigate to="/project/dlf-the-crest-gurgaon-dlf-phase-5-interior-designers" replace />} />

        <Route path="/luxury-interior-designers-in-greater-kailash" element={<Navigate to="/interior-designers-in-greater-kailash" replace />} />

        <Route path="/ambience-creacions-interior-designs-in-gurgaon" element={<Navigate to="/interior-designer-gurgaon" replace />} />

        <Route path="/blogs/decor-ideas/the-magic-touch-of-the-best-interior-designers-in-lucknow" element={<Navigate to="/interior-designers-in-lucknow" replace />} />

        <Route path="/blogs/decor-ideas/how-to-choose-the-best-residential-interior-designer-in-delhi" element={<Navigate to="/blogs/decor-ideas/a-guide-to-residential-interiors-in-delhi" replace />} />

        <Route path="/blogs/innovations/home-interior-designer-in-lucknow" element={<Navigate to="/interior-designers-in-lucknow" replace />} />

        <Route path="/blogs/innovations/interior-design-practices-in-delhi-homes" element={<Navigate to="/blogs/decor-ideas/how-to-achieve-a-perfect-balance-of-luxury-and-functionality-in-your-delhi-home" replace />} />

        <Route path="/blogs/decor-ideas/the-latest-trends-in-interior-design-insights-from-agra-s-experts" element={<Navigate to="/interior-designers-in-agra" replace />} />

        <Route path="/blogs/innovations/how-the-best-interior-designers-in-india-are-redefining-modern-living" element={<Navigate to="/top-interior-designers-in-india" replace />} />

        <Route path="/blogs/innovations/interior-decorator-service-for-home" element={<Navigate to="/blogs/innovations/the-benefits-of-choosing-a-full-service-interior-design-company-for-luxury-homes" replace />} />

        <Route path="/blogs/innovations/top-5-turnkey-interior-design-trends-of-2024" element={<Navigate to="/blogs/decor-ideas/top-luxury-home-interior-design-trend-takeaways-of-2024-what-to-expect-in-high-end-interiors" replace />} />

        <Route path="/blogs/innovations/ transforming-small-spaces-tips-from-leading-interior-designers-in-lucknow" element={<Navigate to="/interior-designers-in-lucknow" replace />} />

        <Route path="/blogs/innovations/interior-design-company-in-delhi-ncr" element={<Navigate to="/blogs/decor-ideas/best-office-interior-designers-in-delhi-ncr-india" replace />} />

        <Route path="/blogs/innovations/top-residential-interior-designer" element={<Navigate to="/blogs/innovations/best-interior-design-firm-in-india " replace />} />

        <Route path="/dlf-the-magnolias-luxury-interiors-in-gurgaon" element={<Navigate to="/project/dlf-the-magnolias-gurgaon-luxury-interior-designers" replace />} />

        <Route path="/blogs/decor-ideas/" element={<Navigate to="/blogs" replace />} />

        <Route path="/3-is-it-true-that-a-designers-influence-leads-to-an-increase-in-the-project-cost" element={<Navigate to="/" replace />} />

        <Route path="/elevate-the-visual-charms-of-your-penthouse-with-proven-interior-designers" element={<Navigate to="/blogs" replace />} />

        <Route path="/utilize-the-experience-of-interior-designers-and-glow-your-home" element={<Navigate to="/blogs/interior-design/ 7-creative-interior-design-tips-for-high-rise-luxury-apartments" replace />} />

        <Route path="/360" element={<Navigate to="/" replace />} />

        <Route path="/interior-design-faq/" element={<Navigate to="/faq" replace />} />

        <Route path="/clients/" element={<Navigate to="/project" replace />} />

        <Route path="/1-what-is-the-difference-between-a-interior-decorator-and-an-interior-designer" element={<Navigate to="/blogs" replace />} />

        <Route path="/hire-the-services-of-a-top-designer-and-elevate-the-beauty-of-your-interiors" element={<Navigate to="/blogs" replace />} />

        <Route path="/blogs/undefined/modern-office-interior-design" element={<Navigate to="/blogs/innovations/modern-office-interior-design" replace />} />

        <Route path="/blogs/undefined/contemporary-bathroom-designs-insights-by-interia-interior-design-company" element={<Navigate to="/blogs/decor-ideas/contemporary-bathroom-designs-insights-by-interia-interior-design-company" replace />} />

        <Route path="/blogs/undefined/how-to-welcome-spring-into-your-living-room-with-floral-motiffs"
          element={<Navigate to="/blogs/decor-ideas/how-to-welcome-spring-into-your-living-room-with-floral-motiffs" replace />} />

        <Route path="/blogs/undefined/add-elegance-residential-design-to-your-home-interiors" element={<Navigate to="/blogs/decor-ideas/add-elegance-residential-design-to-your-home-interiors" replace />} />

        <Route path="/blogs/undefined/who-will-design-your-dream-home" element={<Navigate to="/blogs/innovations/who-will-design-your-dream-home" replace />} />

        <Route path="/blogs/undefined/the-advantages-of-living-in-a-luxury-apartment" element={<Navigate to="/blogs/decor-ideas/the-advantages-of-living-in-a-luxury-apartment" replace />} />

        <Route path="/blogs/undefined/how-interior-designer-firms-in-delhi-can-help-you-revamp-your-home-work-spaces" element={<Navigate to="/blogs/decor-ideas/how-interior-designer-firms-in-delhi-can-help-you-revamp-your-home-work-spaces" replace />} />

        <Route path="/blogs/undefined/new-generation-interior-designer" element={<Navigate to="/blogs/innovations/new-generation-interior-designer" replace />} />

        <Route path="/blogs/undefined/the-benefits-of-modular-kitchen-interior-in-gurgaon" element={
          <Navigate to="/blogs/innovations/the-benefits-of-modular-kitchen-interior-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/how-to-achieve-minimalist-design-with-the-best-interior-designers-in-agra" element={<Navigate to="/blogs/decor-ideas/how-to-achieve-minimalist-design-with-the-best-interior-designers-in-agra" replace
        />} />

        <Route path="/blogs/undefined/how-do-interior-designers-improve-your-space" element={<Navigate to="/blogs/innovations/how-do-interior-designers-improve-your-space" replace />} />

        <Route path="/blogs/undefined/your-perfect-choice-for-best-interiors" element={<Navigate to="/blogs/decor-ideas/your-perfect-choice-for-best-interiors" replace />} />

        <Route path="/blogs/undefined/best-architectural-firms-in-gurgaon" element={<Navigate to="/blogs/decor-ideas/best-architectural-firms-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/top-hospitality-interior-design-trends-what-s-emerging-in-2024" element={<Navigate to="/blogs/decor-ideas/top-hospitality-interior-design-trends-what-s-emerging-in-2024" replace />} />

        <Route path="/blogs/how-interior-designer-firms-in-delhi-can-help-you-revamp-your-home-work-spaces" element={<Navigate to="/blogs/decor-ideas/how-interior-designer-firms-in-delhi-can-help-you-revamp-your-home-work-spaces" replace />} />

        <Route path="/blogs/how-commercial-interior-designers-can-broaden-strengthen-your-identity" element={<Navigate to="/blogs/decor-ideas/how-commercial-interior-designers-can-broaden-strengthen-your-identity" replace />} />

        <Route path="/blogs/undefined/transform-your-space-the-role-of-luxury-interior-design-in-gurgaon" element={<Navigate to="/blogs/innovations/transform-your-space-the-role-of-luxury-interior-design-in-gurgaon" replace />} />

        <Route path="/blogs/how-interior-designing-can-help-you-in-promoting-healthy-office-atmosphere" element={<Navigate to="/blogs/decor-ideas/how-interior-designing-can-help-you-in-promoting-healthy-office-atmosphere" replace />} />

        <Route path="/blogs/undefined/commercial-interior-design-putting-an-end-to-workplace-boredom" element={<Navigate to="/blogs/innovations/commercial-interior-design-putting-an-end-to-workplace-boredom" replace />} />

        <Route path="/blogs/undefined/elegant-staircase-ideas-from-leading-home-interior-designers-in-delhi-ncr" element={<Navigate to="/blogs/innovations/elegant-staircase-ideas-from-leading-home-interior-designers-in-delhi-ncr" replace />} />

        <Route path="/blogs/undefined/top-interior-designers-in-gurgaon" element={<Navigate to="/blogs/innovations/top-interior-designers-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/why-custom-furniture-is-essential-for-luxury-interiors" element={<Navigate to="/blogs/decor-ideas/why-custom-furniture-is-essential-for-luxury-interiors" replace />} />

        <Route path="/blogs/innovations" element={<Navigate to="/" replace />} />

        <Route path="/hire-the-services-of-a-top-designer-and-elevate-the-beauty-of-your-interiors/blogs" element={<Navigate to="/" replace />} />

        <Route path="/360/index.html" element={<Navigate to="/" replace />} />

        <Route path="/blogs/undefined/top-insider-tips-for-a-relaxing-and-elegant-bathroom" element={<Navigate to="/blogs/decor-ideas/top-insider-tips-for-a-relaxing-and-elegant-bathroom" replace />} />

        <Route path="/blogs/undefined/color-trends-for-2025-what-s-hot-in-interior-design-palettes" element={<Navigate to="/blogs/interior-design/color-trends-for-2025-what-s-hot-in-interior-design-palettes" replace />} />

        <Route path="/blogs/decor-ideas/luxury-home-trends-what-s-in-and-what-s-out-for-2024" element={<Navigate to="/blogs/interior-design/luxury-home-trends-whats-in-and-whats-out-for-2024" replace />} />

        <Route path="/blogs/undefined/the-role-of-colour-psychology-in-office-interior-design-insights-from-delhi-designers" element={<Navigate to="/blogs/decor-ideas/the-role-of-colour-psychology-in-office-interior-design-insights-from-delhi-designers" replace />} />

        <Route path="/blogs/undefined/learn-the-basics-of-minimalist-kitchen-designs" element={<Navigate to="/blogs/decor-ideas/learn-the-basics-of-minimalist-kitchen-designs" replace />} />

        <Route path="/blogs/undefined/choose-the-best-architect-for-your-dream-home" element={<Navigate to="/blogs/innovations/choose-the-best-architect-for-your-dream-home" replace />} />

        <Route path="/blogs/undefined/luxury-bathroom-design-trends-creating-a-spa-like-retreat-at-home" element={<Navigate to="/blogs/decor-ideas/luxury-bathroom-design-trends-creating-a-spa-like-retreat-at-home" replace />} />

        <Route path="/blogs/decor-ideas/top-hospitality-interior-design-trends-what-emerging" element={<Navigate to="/blogs/decor-ideas/top-hospitality-interior-design-trends-what-s-emerging-in-2024" replace />} />

        <Route path="/blogs/undefined/how-a-perfect-interior-design-should-look-like" element={<Navigate to="/blogs/decor-ideas/how-a-perfect-interior-design-should-look-like" replace />} />

        <Route path="/blogs/undefined/residential-vs-commercial-interior-designing" element={<Navigate to="/blogs/decor-ideas/residential-vs-commercial-interior-designing" replace />} />

        <Route path="/blogs/how-to-plan-low-budget-interior-designing-for-your-home" element={<Navigate to="/blogs/decor-ideas/how-to-plan-low-budget-interior-designing-for-your-home" replace />} />

        <Route path="/blogs/undefined/contemporary-bathroom-design-ideas-by-bespoke-interior-designers" element={<Navigate to="/blogs/decor-ideas/contemporary-bathroom-design-ideas-by-bespoke-interior-designers" replace />} />

        <Route path="/blogs/undefined/hiring-an-interior-designer-is-an-investment-and-not-an-expense" element={<Navigate to="/blogs/innovations/hiring-an-interior-designer-is-an-investment-and-not-an-expense" replace />} />

        <Route path="/blogs/undefined/top-interior-designer-consultant" element={<Navigate to="/blogs/innovations/top-interior-designer-consultant" replace />} />

        <Route path="/blogs/undefined/refresh-your-home-with-bespoke-interiors-in-delhi" element={<Navigate to="/blogs/innovations/refresh-your-home-with-bespoke-interiors-in-delhi" replace />} />

        <Route path="/blogs/undefined/choose-the-right-interior-designers-in-india" element={<Navigate to="/blogs/decor-ideas/choose-the-right-interior-designers-in-india" replace />} />

        <Route path="/blogs/undefined/how-to-create-the-ultimate-luxury-interiors" element={<Navigate to="/blogs/innovations/how-to-create-the-ultimate-luxury-interiors" replace />} />

        <Route path="/blogs/undefined/modern-cabinet-designs-for-a-clutter-free-bedroom" element={<Navigate to="/blogs/innovations/modern-cabinet-designs-for-a-clutter-free-bedroom" replace />} />

        <Route path="/blogs/undefined/work-with-the-best-interior-designers-in-agra" element={<Navigate to="/blogs/decor-ideas/work-with-the-best-interior-designers-in-agra" replace />} />

        <Route path="/BackendImages/BlogImages/creating-a-functional-kitchen-key-design-ti" element={<Navigate to="/" replace />} />

        <Route path="/blogs/undefined/how-interior-designing-can-help-you-in-promoting-healthy-office-atmosphere" element={<Navigate to="/blogs/decor-ideas/how-interior-designing-can-help-you-in-promoting-healthy-office-atmosphere" replace />} />

        <Route path="/blogs/undefined/luxury-designing-tips-by-top-10-interior-designers-in-gurgaon" element={<Navigate to="/blogs/decor-ideas/luxury-designing-tips-by-top-10-interior-designers-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/the-significance-of-lighting-temperature-in-home-interior-design" element={<Navigate to="/blogs/decor-ideas/the-significance-of-lighting-temperature-in-home-interior-design" replace />} />

        <Route path="/blogs/undefined/looking-for-the-best-interior-designers-in-gurgaon" element={<Navigate to="/blogs/decor-ideas/looking-for-the-best-interior-designers-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/4-steps-to-create-a-luxury-living-space" element={<Navigate to="/blogs/innovations/4-steps-to-create-a-luxury-living-space" replace />} />

        <Route path="/blogs/undefined/embellishments-for-brightening-up-the-home" element={<Navigate to="/blogs/innovations/embellishments-for-brightening-up-the-home" replace />} />

        <Route path="/blogs/undefined/a-guide-to-residential-interiors-in-delhi" element={<Navigate to="/blogs/decor-ideas/a-guide-to-residential-interiors-in-delhi" replace />} />

        <Route path="/blogs/undefined/how-do-interior-designers-in-gurgaon-transform-your-home" element={<Navigate to="/blogs/innovations/how-do-interior-designers-in-gurgaon-transform-your-home" replace />} />

        <Route path="/blogs/undefined/floor-decoration-ideas-for-your-house" element={<Navigate to="/blogs/decor-ideas/floor-decoration-ideas-for-your-house" replace />} />

        <Route path="/blogs/undefined/modern-interior-designs" element={<Navigate to="/blogs/innovations/modern-interior-designs" replace />} />

        <Route path="/blogs/undefined/the-defining-role-of-color-in-interior-designing" element={<Navigate to="/blogs/innovations/the-defining-role-of-color-in-interior-designing" replace />} />

        <Route path="/blogs/undefined/get-the-perfect-interiors-for-your-dream-home" element={<Navigate to="/blogs/innovations/get-the-perfect-interiors-for-your-dream-home" replace />} />

        <Route path="/blogs/undefined/designing-your-dream-bedroom-luxury-features-you-should-consider" element={<Navigate to="/blogs/decor-ideas/designing-your-dream-bedroom-luxury-features-you-should-consider" replace />} />

        <Route path="/blogs/undefined/why-custom-modular-solutions-are-the-future-of-luxury-interiors" element={<Navigate to="/blogs/innovations/why-custom-modular-solutions-are-the-future-of-luxury-interiors" replace />} />

        <Route path="/blogs/undefined/best-tips-to-select-interior-designers-for-your-dream-home" element={<Navigate to="/blogs/innovations/best-tips-to-select-interior-designers-for-your-dream-home" replace />} />

        <Route path="/blogs/hiring-an-interior-designer-is-an-investment-and-not-an-expense" element={<Navigate to="/blogs/innovations/hiring-an-interior-designer-is-an-investment-and-not-an-expense" replace />} />

        <Route path="/blogs/undefined/top-interior-design-firms" element={<Navigate to="/blogs/innovations/top-interior-design-firms" replace />} />

        <Route path="/elevate-the-visual-charms-of-your-penthouse-with-proven-interior-designers/bespoke-interior-designing" element={<Navigate to="/bespoke-interior-designing" replace />} />

        <Route path="/blogs/decor-ideas/multi-functional-furniture-solutions-for-flexible-and-modern-living-spaces" element={<Navigate to="/blogs/interior-design/multi-functional-furniture-solutions-for-flexible-and-modern-living-spaces" replace />} />

        <Route path="/project/krones" element={<Navigate to="/project/krones-sector-23-gurgaon-interior-designers" replace />} />

        <Route path="/project/sumit-araya" element={<Navigate to="/project/sumit-araya-palam-vihar-interior-designers" replace />} />

        <Route path="/blogs/decor-ideas/7-creative-interior-design-tips-for-high-rise-luxury-apartments" element={<Navigate to="/blogs/interior-design/7-creative-interior-design-tips-for-high-rise-luxury-apartments" replace />} />

        <Route path="/blogs/choose-the-top-interior-designers-for-your-dream-home" element={<Navigate to="/blogs/decor-ideas/choose-the-top-interior-designers-for-your-dream-home" replace />} />

        <Route path="/blogs/get-some-brilliant-ideas-about-office-interior-design" element={<Navigate to="/blogs/decor-ideas/get-some-brilliant-ideas-about-office-interior-design" replace />} />

        <Route path="/blogs/undefined/top-10-ideas-for-luxury-interior-designing-at-home" element={<Navigate to="/blogs/decor-ideas/top-10-ideas-for-luxury-interior-designing-at-home" replace />} />

        <Route path="/project/ireo-skyon" element={<Navigate to="/project/ireo-skyon-sector-60-gurugram-interior-designer" replace />} />

        <Route path="/project/exotica" element={<Navigate to="/project/exotica-sector-45-gurgaon-interior-designers" replace />} />

        <Route path="/project/aralias-ii" element={<Navigate to="/project/dlf-aralias-ii-luxury-interior-designers" replace />} />

        <Route path="/project/malibutown" element={<Navigate to="/project/malibu-town-sector-47-gurgaon-interior-designers" replace />} />

        <Route path="/project/west-delhi" element={<Navigate to="/project/west-delhi-interior-designer" replace />} />

        <Route path="/project/new-dales-school" element={<Navigate to="/project/new-dales-school-moradabad-interior-design" replace />} />

        <Route path="/project/emaar-marbellas" element={<Navigate to="/ " replace />} />

        <Route path="/blogs/residential-vs-commercial-interior-designing" element={<Navigate to="/blogs/decor-ideas/residential-vs-commercial-interior-designing" replace />} />

        <Route path="/blogs/best-office-interior-designers-in-delhi-ncr-india" element={<Navigate to="/blogs/decor-ideas/best-office-interior-designers-in-delhi-ncr-india" replace />} />

        <Route path="/blogs/give-a-touch-of-sophistication-to-your-interiors" element={<Navigate to="/blogs/decor-ideas/sophisticated-interior-designers-in-gurgaon" replace />} />

        <Route path="/blogs/luxury-interior-design-no-substitute-for-it" element={<Navigate to="/blogs/innovations/luxury-interior-design-no-substitute-for-it" replace />} />

        <Route path="/blogs/exemplify-the-best-interior-design" element={<Navigate to="/blogs/decor-ideas/exemplify-the-best-interior-design" replace />} />

        <Route path="/blogs/some-hallmarks-of-modern-interior-design" element={<Navigate to="/blogs/innovations/some-hallmarks-of-modern-interior-design" replace />} />

        <Route path="/blogs/undefined/how-to-choose-furniture-for-a-small-space" element={<Navigate to="/blogs/decor-ideas/how-to-choose-furniture-for-a-small-space" replace />} />

        <Route path="/blogs/undefined/best-interior-designing-in-gurgaon" element={<Navigate to="/blogs/decor-ideas/best-interior-designing-in-gurgaon" replace />} />

        <Route path="/blogs/undefined/transforming-your-home-office-in-lucknow-design-ideas-for-productivity-comfort" element={<Navigate to="/blogs/decor-ideas/transforming-your-home-office-in-lucknow-design-ideas-for-productivity-comfort" replace />} />

        <Route path="/project/malibu" element={<Navigate to="/project/malibu-town-sector-47-gurgaon-interior-designers" replace />} />

        <Route path="/blogs/undefined/different-types-of-interior-design-styles" element={<Navigate to="/blogs/innovations/different-types-of-interior-design-styles" replace />} />

        <Route path="/blogs/undefined/the-importance-of-texture-in-interior-design-creating-depth-and-interest"	element={<Navigate to="/blogs/interior-design/the-importance-of-texture-in-interior-design-creating-depth-and-interest" replace/>}/>

        <Route path="/blogs/undefined/exemplify-the-best-interior-design"	element={<Navigate to="/blogs/decor-ideas/exemplify-the-best-interior-design" replace/>}/>

        <Route path="/blogs/undefined/why-turnkey-interior-design-solutions-save-time-and-money-for-luxury-homes"	element={<Navigate to="/blogs/innovations/why-turnkey-interior-design-solutions-save-time-and-money-for-luxury-homes" replace/>}/>

        <Route path="/blogs/undefined/why-are-turnkey-interior-design-ideas-worth-it"	element={<Navigate to="/blogs/decor-ideas/why-are-turnkey-interior-design-ideas-worth-it" replace/>}/>

        <Route path="/blogs/undefined/interia-interiors-in-gurgaon"	element={<Navigate to="/blogs/decor-ideas/interia-interiors-in-gurgaon" replace/>}/>

        <Route path="/blogs/undefined/best-interior-design-company-in-gurgaon"	element={<Navigate to="/blogs/decor-ideas/best-interior-design-company-in-gurgaon" replace/>}/>

        <Route path="/blogs/undefined/cultural-influences-in-interior-design-exploring-global-styles-and-trends"	element={<Navigate to="/blogs/interior-design/cultural-influences-in-interior-design-exploring-global-styles-and-trends" replace/>}/>

        <Route path="/blogs/undefined/interior-design-with-rajputana-architecture-styles"	element={<Navigate to="/blogs/decor-ideas/interior-design-with-rajputana-architecture-styles" replace/>}/>

        <Route path="/blogs/undefined/interior-designing-ideas-inspired-by-mughal-architecture"	element={<Navigate to="/blogs/decor-ideas/interior-designing-ideas-inspired-by-mughal-architecture" replace/>}/>

        <Route path="/blogs/undefined/5-important-things-to-know-before-starting-interior-designing-for-your-home"	element={<Navigate to="/blogs/decor-ideas/5-important-things-to-know-before-starting-interior-designing-for-your-home" replace/>}/>

        <Route path="/blogs/undefined/color-trends-for-2025-whats-hot-in-interior-design-palettes"	element={<Navigate to="/blogs/interior-design/color-trends-for-2025-what-s-hot-in-interior-design-palettes" replace/>}/>

        <Route path="/blogs/undefined/ai-meets-interior-design-the-rise-of-intelligent-room-customization"	element={<Navigate to="/blogs/innovations/ai-meets-interior-design-the-rise-of-intelligent-room-customization" replace/>}/>

        <Route path="/blogs/undefined/eco-friendly-furniture-trends-recycled-and-upcycled-decor-innovations-for-2024"	element={<Navigate to="/blogs/innovations/eco-friendly-furniture-trends-recycled-and-upcycled-decor-innovations-for-2024" replace/>}/>

        <Route path="/blogs/undefined/popular-interior-design-styles-demystified"	element={<Navigate to="/blogs/innovations/popular-interior-design-styles-demystified" replace/>}/>

        <Route path="/blogs/undefined/perfect-recipe-for-outstanding-residential-interior-design"	element={<Navigate to="/blogs/decor-ideas/perfect-recipe-for-outstanding-residential-interior-design" replace/>}/>

        <Route path="/blogs/undefined/choose-the-top-interior-designers-for-your-dream-home"	element={<Navigate to="/blogs/decor-ideas/choose-the-top-interior-designers-for-your-dream-home" replace/>}/>

        <Route path="/blogs/undefined/corporate-office-design-motivating-innovation-and-productivity"	element={<Navigate to="/blogs/decor-ideas/corporate-office-design-motivating-innovation-and-productivity" replace/>}/>

        <Route path="/blogs/undefined/the-art-of-mixing-patterns-and-textures-in-interior-design"	element={<Navigate to="/blogs/decor-ideas/the-art-of-mixing-patterns-and-textures-in-interior-design" replace/>}/>

        <Route path="/blogs/undefined/best-office-interior-designers-in-delhi-ncr-india"	element={<Navigate to="/blogs/decor-ideas/best-office-interior-designers-in-delhi-ncr-india" replace/>}/>

        <Route path="/blogs/undefined/differences-between-commercial-and-residential-interior-designing"	element={<Navigate to="/blogs/decor-ideas/differences-between-commercial-and-residential-interior-designing" replace/>}/>

        <Route path="/blogs/undefined/basic-principles-of-interior-design"	element={<Navigate to="/blogs/decor-ideas/basic-principles-of-interior-design" replace/>}/>

        <Route path="/blogs/undefined/how-to-plan-low-budget-interior-designing-for-your-home"	element={<Navigate to="/blogs/decor-ideas/how-to-plan-low-budget-interior-designing-for-your-home" replace/>}/>

        <Route path="/blogs/undefined/best-interior-designer-in-agra"	element={<Navigate to="/blogs/innovations/best-interior-designer-in-agra" replace/>}/>

        <Route path="/blogs/undefined/7-creative-interior-design-tips-for-high-rise-luxury-apartments"	element={<Navigate to="/blogs/interior-design/7-creative-interior-design-tips-for-high-rise-luxury-apartments" replace/>}/>

        <Route path="/blogs/undefined/understanding-scale-and-proportion-in-interior-design"	element={<Navigate to="/blogs/interior-design/understanding-scale-and-proportion-in-interior-design" replace/>}/>

        <Route path="/blogs/undefined/5-brilliant-interior-design-ideas-for-modern-homes"	element={<Navigate to="/blogs/decor-ideas/5-brilliant-interior-design-ideas-for-modern-homes" replace/>}/>

        <Route path="/blogs/undefined/interia-bringing-sophistication-to-your-quality-of-living"	element={<Navigate to="/blogs/innovations/interia-bringing-sophistication-to-your-quality-of-living" replace/>}/>

        <Route path="/blogs/undefined/the-art-of-custom-furniture-tailoring-luxury-for-every-space"	element={<Navigate to="/blogs/interior-design/the-art-of-custom-furniture-tailoring-luxury-for-every-space" replace/>}/>

        <Route path="/blogs/undefined/five-commercial-interior-designing-ideas"	element={<Navigate to="/blogs/innovations/five-commercial-interior-designing-ideas" replace/>}/>

        <Route path="/blogs/undefined/interior-designers-trend-in-india"	element={<Navigate to="/blogs/innovations/interior-designers-trend-in-india" replace/>}/>

        <Route path="/blogs/undefined/postmodern-interior-of-2021"	element={<Navigate to="/blogs/decor-ideas/postmodern-interior-of-2021" replace/>}/>

        <Route path="/blogs/undefined/top-luxury-home-interior-design-trend-takeaways-of-2024-what-to-expect-in-high-end-interiors"	element={<Navigate to="/blogs/decor-ideas/top-luxury-home-interior-design-trend-takeaways-of-2024-what-to-expect-in-high-end-interiors" replace/>}/>

        <Route path="/blogs/undefined/post-modern-architecture-to-make-you-elite"	element={<Navigate to="/blogs/decor-ideas/post-modern-architecture-to-make-you-elite" replace/>}/>

        <Route path="/blogs/undefined/office-architect-design-solutions"	element={<Navigate to="/blogs/decor-ideas/office-architect-design-solutions" replace/>}/>

        <Route path="/blogs/undefined/the-rise-of-customized-interiors-why-gurgaon-homeowners-are-opting-for-personalized-designs"	element={<Navigate to="/blogs/decor-ideas/the-rise-of-customized-interiors-why-gurgaon-homeowners-are-opting-for-personalized-designs" replace/>}/>

        <Route path="/blogs/undefined/best-interior-designers-in-delhi"	element={<Navigate to="/blogs/innovations/best-interior-designers-in-delhi" replace/>}/>

        <Route path="/blogs/undefined/monochromatic-magic-achieving-cohesion-with-tone-on-tone-decor"	element={<Navigate to="/blogs/interior-design/monochromatic-magic-achieving-cohesion-with-tone-on-tone-decor" replace/>}/>

        <Route path="/blogs/undefined/the-benefits-of-choosing-a-full-service-interior-design-company-for-luxury-homes"	element={<Navigate to="/blogs/innovations/the-benefits-of-choosing-a-full-service-interior-design-company-for-luxury-homes" replace/>}/>

        <Route path="/blogs/undefined/developing-sustainable-luxury-insights-from-the-top-interior-design-firms-in-delhi"	element={<Navigate to="/blogs/decor-ideas/developing-sustainable-luxury-insights-from-the-top-interior-design-firms-in-delhi" replace/>}/>

        <Route path="/blogs/undefined/home-office-design-creating-a-productive-and-inspiring-workspace"	element={<Navigate to="/blogs/interior-design/home-office-design-creating-a-productive-and-inspiring-workspace" replace/>}/>

        <Route path="/blogs/undefined/best-interior-design-firm-in-india"	element={<Navigate to="/blogs/innovations/best-interior-design-firm-in-india" replace/>}/>

        <Route path="/blogs/undefined/from-concept-to-reality-the-process-behind-custom-made-furniture"	element={<Navigate to="/blogs/decor-ideas/from-concept-to-reality-the-process-behind-custom-made-furniture" replace/>}/>

        <Route path="/blogs/undefined/how-to-create-a-statement-entrance-with-bespoke-interiors"	element={<Navigate to="/blogs/decor-ideas/how-to-create-a-statement-entrance-with-bespoke-interiors" replace/>}/>

        <Route path="/blogs/undefined/the-power-of-lighting-in-interior-design-transforming-spaces-with-modern-solutions"	element={<Navigate to="/blogs/interior-design/the-power-of-lighting-in-interior-design-transforming-spaces-with-modern-solutions" replace/>}/>

        <Route path="/blogs/undefined/the-future-of-interior-design-innovations-shaping-tomorrows-spaces"	element={<Navigate to="/blogs/interior-design/the-future-of-interior-design-innovations-shaping-tomorrows-spaces" replace/>}/>

        <Route path="/blogs/undefined/features-that-make-residential-interior-design-distinctive"	element={<Navigate to="/blogs/decor-ideas/features-that-make-residential-interior-design-distinctive" replace/>}/>

        <Route path="/blogs/undefined/get-some-brilliant-ideas-about-office-interior-design"	element={<Navigate to="/blogs/decor-ideas/get-some-brilliant-ideas-about-office-interior-design" replace/>}/>

        <Route path="/blogs/undefined/maximizing-small-spaces-creative-tips-for-apartment-living"	element={<Navigate to="/blogs/innovations/maximizing-small-spaces-creative-tips-for-apartment-living" replace/>}/>

        <Route path="/blogs/undefined/multi-functional-furniture-solutions-for-flexible-and-modern-living-spaces"	element={<Navigate to="/blogs/interior-design/multi-functional-furniture-solutions-for-flexible-and-modern-living-spaces" replace/>}/>

        <Route path="/blogs/undefined/some-hallmarks-of-modern-interior-design"	element={<Navigate to="/blogs/innovations/some-hallmarks-of-modern-interior-design" replace/>}/>

        <Route path="/blogs/undefined/5-tips-for-making-interior-design-of-your-office-look-great"	element={<Navigate to="/blogs/decor-ideas/5-tips-for-making-interior-design-of-your-office-look-great" replace/>}/>

        <Route path="/blogs/undefined/selecting-classic-styles-for-your-contemporary-living-room-in-lucknow"	element={<Navigate to="/blogs/innovations/selecting-classic-styles-for-your-contemporary-living-room-in-lucknow" replace/>}/>

        <Route path="/blogs/undefined/10-easy-hacks-to-manage-space"	element={<Navigate to="/blogs/innovations/10-easy-hacks-to-manage-space" replace/>}/>

        <Route path="/blogs/undefined/creating-cozy-spaces-the-art-of-designing-inviting-interiors"	element={<Navigate to="/blogs/interior-design/creating-cozy-spaces-the-art-of-designing-inviting-interiors" replace/>}/>

        <Route path="/blogs/undefined/turnkey-interior-design-solutions"	element={<Navigate to="/blogs/decor-ideas/turnkey-interior-design-solutions" replace/>}/>

        <Route path="/blogs/undefined/the-importance-of-architectural-design-for-houses"	element={<Navigate to="/blogs/innovations/the-importance-of-architectural-design-for-houses" replace/>}/>

        <Route path="/blogs/undefined/interior-designing-trends-inspired-by-the-late-70s"	element={<Navigate to="/blogs/decor-ideas/interior-designing-trends-inspired-by-the-late-70s" replace/>}/>

        <Route path="/blogs/undefined/get-the-interiors-that-fulfils-your-needs-elegantly"	element={<Navigate to="/blogs/innovations/get-the-interiors-that-fulfils-your-needs-elegantly" replace/>}/>

        <Route path="/blogs/undefined/how-to-select-best-interior-designers-in-gurgaon"	element={<Navigate to="/blogs/decor-ideas/how-to-select-best-interior-designers-in-gurgaon" replace/>}/>

        <Route path="/blogs/undefined/5-sophisticated-design-tricks-that-brings-positivity-in-office"	element={<Navigate to="/blogs/decor-ideas/5-sophisticated-design-tricks-that-brings-positivity-in-office" replace/>}/>

        <Route path="/blogs/undefined/luxury-interior-design-no-substitute-for-it"	element={<Navigate to="/blogs/innovations/luxury-interior-design-no-substitute-for-it" replace/>}/>

        <Route path="/blogs/undefined/how-to-achieve-a-perfect-balance-of-luxury-and-functionality-in-your-delhi-home"	element={<Navigate to="/blogs/decor-ideas/how-to-achieve-a-perfect-balance-of-luxury-and-functionality-in-your-delhi-home" replace/>}/>

        <Route path="/blogs/undefined/luxury-home-trends-whats-in-and-whats-out-for-2024"	element={<Navigate to="/blogs/interior-design/luxury-home-trends-whats-in-and-whats-out-for-2024" replace/>}/>

        <Route path="/blogs/undefined/luxury-home-trends-whats-in-and-whats-out-for-2024"	element={<Navigate to="/blogs/interior-design/luxury-home-trends-whats-in-and-whats-out-for-2024" replace/>}/>

        <Route path="/blogs/undefined/contrast-between-traditional-and-modern-interior-design"	element={<Navigate to="/blogs/lighting-ideas/contrast-between-traditional-and-modern-interior-design" replace/>}/>

        <Route path="/blogs/undefined/give-a-bohemian-touch-to-your-interior"	element={<Navigate to="/blogs/decor-ideas/give-a-bohemian-touch-to-your-interior" replace/>}/>

        <Route path="/blogs/undefined/the-importance-of-art-in-luxury-interiors-curating-a-personal-gallery"	element={<Navigate to="/blogs/decor-ideas/the-importance-of-art-in-luxury-interiors-curating-a-personal-gallery" replace/>}/>

        <Route path="/blogs/undefined/nothing-can-substitute-luxurious-interior-design-for-your-home"	element={<Navigate to="/blogs/decor-ideas/nothing-can-substitute-luxurious-interior-design-for-your-home" replace/>}/>

        <Route path="/blogs/undefined/essentials-of-the-best-interior-design"	element={<Navigate to="/blogs/decor-ideas/essentials-of-the-best-interior-design" replace/>}/>

        <Route path="/blogs/undefined/corporate-office-design-the-guide-to-creating-an-efficient-working-space"	element={<Navigate to="/blogs/decor-ideas/corporate-office-design-the-guide-to-creating-an-efficient-working-space" replace/>}/>

        <Route path="/blogs/undefined/some-effective-tips-for-beautiful-window-seating"	element={<Navigate to="/blogs/lighting-ideas/some-effective-tips-for-beautiful-window-seating" replace/>}/>

        <Route path="/blogs/undefined/create-an-unapologetically-playful-room"	element={<Navigate to="/blogs/lighting-ideas/create-an-unapologetically-playful-room" replace/>}/>

        <Route path="/blogs/undefined/some-reasons-for-finding-the-right-architect"	element={<Navigate to="/blogs/decor-ideas/some-reasons-for-finding-the-right-architect" replace/>}/>

        <Route path="/blogs/undefined/when-you-should-hire-a-commercial-interior-designer"	element={<Navigate to="/blogs/decor-ideas/when-you-should-hire-a-commercial-interior-designer" replace/>}/>

        <Route path="/blogs/undefined/accentuate-your-living-space-with-modern-and-contemporary-furniture-style"	element={<Navigate to="/blogs/innovations/accentuate-your-living-space-with-modern-and-contemporary-furniture-style" replace/>}/>

        <Route path="/blogs/undefined/sophisticated-interior-designers-in-gurgaon"	element={<Navigate to="/blogs/decor-ideas/sophisticated-interior-designers-in-gurgaon" replace/>}/>

        <Route path="/blogs/undefined/the-impact-of-technology-on-interior-design-insights-from-delhi-s-top-design-firms"	element={<Navigate to="/blogs/decor-ideas/the-impact-of-technology-on-interior-design-insights-from-delhi-s-top-design-firms" replace/>}/>

        <Route path="/blogs/undefined/modern-interior-designing-requirements"	element={<Navigate to="/blogs/innovations/modern-interior-designing-requirements" replace/>}/>

        <Route path="/blogs/undefined/the-art-of-bespoke-interior-designing-in-delhi-why-it-s-worth-the-investment"	element={<Navigate to="/blogs/innovations/the-art-of-bespoke-interior-designing-in-delhi-why-it-s-worth-the-investment" replace/>}/>

        <Route path="/blogs/undefined/the-rise-of-custom-furniture-tailoring-luxury-to-your-unique-style"	element={<Navigate to="/blogs/innovations/the-rise-of-custom-furniture-tailoring-luxury-to-your-unique-style" replace/>}/>

        <Route path="/blogs/undefined/why-choose-the-best-interior-designers-in-delhi"	element={<Navigate to="/blogs/innovations/why-choose-the-best-interior-designers-in-delhi" replace/>}/>

        <Route path="/blogs/undefined/looking-for-a-top-interior-designer-in-delhi"	element={<Navigate to="/blogs/decor-ideas/looking-for-a-top-interior-designer-in-delhi" replace/>}/>

        <Route path="/blogs/undefined/how-you-do-your-architectural-designing-the-right-way"	element={<Navigate to="/blogs/decor-ideas/how-you-do-your-architectural-designing-the-right-way" replace/>}/>

      </Routes>
    </>
  )
}
