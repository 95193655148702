import { useState, useEffect, lazy, Suspense, useCallback, memo } from "react";
import { BrowserRouter as Router, useLocation} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop";
import SEO from "./Pages/helper/SEO";
import { fetchSEOData } from "./Pages/helper/fetchSEOData";
import { SourceUrlProvider } from './context/SourceUrlContext';
import "./App.css";
import "./external.css";
import "animate.css/animate.min.css";
import "./custom.css";
import "./landing.css"
import "./responsive.css";
import { WOW } from 'wowjs';
import RouterFie from "./RouterFie";

const Layout = lazy(() => import("./Layout/Layout"));

function useSEOData(pathname) {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSEOData(pathname);
      setSeoData(data);
    };
    fetchData();
  }, [pathname]);

  return seoData;
}

const NormalizeTrailingSlash = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      window.history.replaceState(
        null,
        '',
        location.pathname.slice(0, -1) + location.search + location.hash
      );
    }
  }, [location]);

  return null;
};

const AppContent = memo(() => {
  const location = useLocation();
  const seoData = useSEOData(location.pathname);
  // const newPath = redirectsMap[location.pathname];

  // Store referrer or current URL in sessionStorage
  // useEffect(() => {
  //   const sessionCheck = sessionStorage.getItem("sourceUrlSession");
  //   console.log(sessionCheck, "source url")
  //   if (!sessionCheck) {
  //     const referrer = document.referrer || window.location.href;
  //     sessionStorage.setItem("sourceUrlSession", referrer);
  //   }
  // }, []);

  useEffect(() => {
    const wow = new WOW();
    wow.init();
    return () => wow.sync();
  }, []);

  return (

    <HelmetProvider>
      <SEO seo={seoData} />
      <NormalizeTrailingSlash />
      <Suspense fallback={
            <>
              <div className="loader-only-css">
                <div className="loader-inner-css">
                  <div></div>
                </div>
              </div>
            </>
            }>
        <Layout>
          <ScrollToTop />
          <RouterFie/>
        </Layout>
      </Suspense>
    </HelmetProvider>
  );
});

function App() {
  return (
    <Router>
      <SourceUrlProvider>
        <AppContent />
      </SourceUrlProvider>
    </Router>
  );
}

export default App;
